<template>
  <div class="card-columns">
    <div class="card sits-border-info">
      <div class="card-header">
        <h5 class="text-info mb-0">Consent</h5>
      </div>
      <div class="card-body">
        <div v-if="propsData.upload_identity">
          <dl class="row">
            <dt class="col-sm-4">Consent document</dt>
            <dd class="col-sm-8">
              <wdImageAvatarPreview
                :title="'Consent document'"
                :coverImgUrl="propsData.consent_partner"
                :fontAwClass="'fas fa-file-alt'"
              ></wdImageAvatarPreview>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";
export default {
  name: "reviewBlock3",
  props: {
    propsData: {
      required: true,
      type: Object,
    },
  },
  components: {
    wdImageAvatarPreview,
  },
};
</script>